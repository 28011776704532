<template>
    <v-container>
        <base-section-heading
        title="about.results.title">
        </base-section-heading>

        <SectionWithTable
        v-for="section in sections"
        :key="section.key"
        :id="section.id"
        :title="section.title"
        :text="section.text"
        :items="section.items"
        :type="section.type"
        ></SectionWithTable>

    </v-container>
</template>

<script>
import SectionWithTable from '@/components/docs/SectionWithTable'

export default {
    components: {
        SectionWithTable,
    },
    data() {
        return {
            sections: [
                {
                    key: 1,
                    id: 'corpus-section',
                    type: 'corpus',
                    title: 'about.results.section-1.title',
                    text: 'about.results.section-1.text',
                    items: [
                        {
                            name: 'Gli anni giovanili (1939-1946)',
                            id: '10.6092/unibo/aldomoro1.1.0',
                            desc: 'about.results.section-1.item-1.desc',
                            download: 'basics.downloadButton'
                        },
                        {
                            name: 'Al ministero degli Esteri e all’opposizione nel partito (giugno 1968 – giugno 1973)',
                            id: '10.48678/unibo/aldomoro1.4.1',
                            desc: 'about.results.section-1.item-6.desc',
                            download: 'basics.downloadButton'
                        },
                        {
                            name: 'Il ritorno al centro-sinistra e la “solidarietà nazionale” (giugno 1973 – maggio 1978)',
                            id: '10.48678/unibo/aldomoro1.4.2',
                            desc: 'about.results.section-1.item-7.desc',
                            download: 'basics.downloadButton'
                        }
                    ],
                },
                {
                    key: 2,
                    id: 'codes-section',
                    title: 'about.results.section-2.title',
                    text: 'about.results.section-2.text',
                    items: [
                        {
                            name: 'about.results.section-2.item-1.name',
                            id: 'about.results.section-2.item-1.id',
                            desc: 'about.results.section-2.item-1.desc',
                        },
                        {
                            name: 'about.results.section-2.item-2.name',
                            id: 'about.results.section-2.item-2.id',
                            desc: 'about.results.section-2.item-2.desc',
                        },
                        {
                            name: 'about.results.section-2.item-3.name',
                            id: 'about.results.section-2.item-3.id',
                            desc: 'about.results.section-2.item-3.desc',
                        },
                        {
                            name: 'about.results.section-2.item-4.name',
                            id: 'about.results.section-2.item-4.id',
                            desc: 'about.results.section-2.item-4.desc',
                        },
                        {
                            name: 'about.results.section-2.item-5.name',
                            id: 'about.results.section-2.item-5.id',
                            desc: 'about.results.section-2.item-5.desc',
                        },
                        {
                            name: 'about.results.section-2.item-6.name',
                            id: 'about.results.section-2.item-6.id',
                            desc: 'about.results.section-2.item-6.desc',
                        },
                        /*
                        {
                            name: 'about.results.section-2.item-7.name',
                            id: 'about.results.section-2.item-7.id',
                            desc: 'about.results.section-2.item-7.desc',
                        }
                        */
                    ]
                },
                {
                    key: 3,
                    id: 'reports-section',
                    title: 'about.results.section-3.title',
                    text: 'about.results.section-3.text',
                    items: [
                        {
                            name: 'about.results.section-3.item-1.name',
                            id: 'about.results.section-3.item-1.id',
                            desc: 'about.results.section-3.item-1.desc',
                        },
                    ]
                },
                {
                    key: 4,
                    id: 'others-section',
                    title: 'about.results.section-4.title',
                    text: 'about.results.section-4.text',
                    items: [
                        {
                            name: 'about.results.section-4.item-1.name',
                            id: 'about.results.section-4.item-1.id',
                            desc: 'about.results.section-4.item-1.desc',
                        },
                        {
                            name: 'about.results.section-4.item-2.name',
                            id: 'about.results.section-4.item-2.id',
                            desc: 'about.results.section-4.item-2.desc',
                        },
                        {
                            name: 'about.results.section-4.item-3.name',
                            id: 'about.results.section-4.item-3.id',
                            desc: 'about.results.section-4.item-3.desc',
                        },
                        {
                            name: 'about.results.section-4.item-4.name',
                            id: 'about.results.section-4.item-4.id',
                            desc: 'about.results.section-4.item-4.desc',
                        }
                    ]
                },
            ]
        }
    }
}
</script>
