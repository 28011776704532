<template>
    <v-container
    class="section">
        <v-card>
            <v-card-title>{{ $t(title) }}</v-card-title>
            <v-card-text>
                <p>
                    {{ $t(text) }}
                </p>
            </v-card-text>
            <div v-if="type==='corpus'">
                <Table
                :items="items"
                ></Table>
            </div>
            <div v-else>
                <TableWithoutDownload
                :items="items"
                ></TableWithoutDownload>
            </div>
        </v-card>
    </v-container>
</template>

<script>
import Table from '@/components/base/Table';
import TableWithoutDownload from '@/components/base/TableWithoutDownload'
export default {
    components: {
        Table,
        TableWithoutDownload
    },
    props: {
        title: {
            type: Text
        },
        text: {
            type: Text
        },
        items: {
            type: Array,
            default: () => []
        },
        type: {
            type: Text
        }
    }
}
</script>